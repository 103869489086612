
export const galleryImg = [
  {
    id: 1,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/race_image_2023_1.jpg",
    imageAlt: "Race Day Images Feb 2023",
    superTitle: "Race Day Images Feb 2023",
    title: "Race Day Images Feb 2023",
  },
  {
    id: 2,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/race_image_2023_2.jpg",
      imageAlt: "Race Day Images Feb 2023",
      superTitle: "Race Day Images Feb 2023",
      title: "Race Day Images Feb 2023",
  },
  {
    id: 3,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/race_image_2023_3.jpg",
      imageAlt: "Race Day Images Feb 2023",
      superTitle: "Race Day Images Feb 2023",
      title: "Race Day Images Feb 2023",
  },
  {
    id: 4,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/race_image_2023_4.jpg",
      imageAlt: "Race Day Images Feb 2023",
      superTitle: "Race Day Images Feb 2023",
      title: "Race Day Images Feb 2023",
  },
  {
    id: 5,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/race_image_2023_5.jpg",
      imageAlt: "Race Day Images Feb 2023",
      superTitle: "Race Day Images Feb 2023",
      title: "Race Day Images Feb 2023",
  },
  {
    id: 6,
    img:
      "https://cdn-prod.heartfulness.org/greenkanharun/2023/Event_pic_8.jpg",
      imageAlt: "Race Day Images Feb 2023",
      superTitle: "Race Day Images Feb 2023",
      title: "Race Day Images Feb 2023",
  },];
